import { FC } from 'react';
import './Content.scss';
import { PostList } from './PostList';

export const Content: FC = () => {
	return (
		<div className="content pure-u-1 pure-u-md-3-4">
			<div>
				<PostList />
			</div>
		</div>
	);
};
