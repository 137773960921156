import React, { FC } from 'react';
import './Sidebar.scss';
import logo from '../Resources/logo.png';

export const Sidebar: FC = () => {
	return (
		<div className="sidebar pure-u-1 pure-u-md-1-4">
			<div className="header">
				<img src={logo} />
				<h1 className="brand-title">The Code Juggler</h1>
				<h2 className="brand-tagline">
					01001111 01101000 00101100 00100000 01101000 01101001 00100000 01110100 01101000 01100101 01110010 01100101 00100001{' '}
				</h2>

				{/* <nav className="nav">
					<ul className="nav-list">
						<li className="nav-item">
							<a className="pure-button" href="http://purecss.io">
								Pure
							</a>
						</li>
					</ul>
				</nav> */}
			</div>
		</div>
	);
};
