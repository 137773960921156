import React, { FC } from 'react';
import { Content } from '../Components/Content';
import { Sidebar } from '../Components/Sidebar';
import './App.scss';

export const App: FC = () => {
	return (
		<div id="layout" className="pure-g">
			<Sidebar />
			<Content />
		</div>
	);
};

export default App;
